import React from 'react'
import '../assets/css/owl.carousel.min.css'
import client1 from "../assets/images/client-1.jpg"
import client2 from "../assets/images/client-2.jpg"


function ClientSection() {
  return (
    <section className="client_section ">
    <div className="container">
      <div className="heading_container heading_center">
        <h2>
          What Our Clients Say
        </h2>
      </div>
      <div className="carousel-wrap layout_padding2-top ">
        <div className="owl-carousel">
          <div className="item">
            <div className="box">
              <div className="client_id">
                <div className="img-box">
                  <img src={client1} alt=""/>
                </div>
                <div className="client_detail">
                  <div className="client_info">
                    <h6>
                      Jorch morik
                    </h6>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </div>
                  <i className="fa fa-quote-left" aria-hidden="true"></i>
                </div>
              </div>
              <div className="client_text">
                <p>
                  chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum
                </p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="box">
              <div className="client_id">
                <div className="img-box">
                  <img src={client2} alt=""/>
                </div>
                <div className="client_detail">
                  <div className="client_info">
                    <h6>
                      Jorch morik
                    </h6>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </div>
                  <i className="fa fa-quote-left" aria-hidden="true"></i>
                </div>
              </div>
              <div className="client_text">
                <p>
                  chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum
                </p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="box">
              <div className="client_id">
                <div className="img-box">
                  <img src={client1} alt=""/>
                </div>
                <div className="client_detail">
                  <div className="client_info">
                    <h6>
                      Jorch morik
                    </h6>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </div>
                  <i className="fa fa-quote-left" aria-hidden="true"></i>
                </div>
              </div>
              <div className="client_text">
                <p>
                  chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum
                </p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="box">
              <div className="client_id">
                <div className="img-box">
                  <img src={client2} alt=""/>
                </div>
                <div className="client_detail">
                  <div className="client_info">
                    <h6>
                      Jorch morik
                    </h6>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </div>
                  <i className="fa fa-quote-left" aria-hidden="true"></i>
                </div>
              </div>
              <div className="client_text">
                <p>
                  chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  )
}

export default ClientSection