import React from 'react';
import aboutImage from "../assets/images/about-img.jpg";
import data from '../data';
import { Link } from 'react-router-dom';

function AboutSection() {
  const { webDevelopment, mobileDevelopment, uiuxDesign, videoEditing, graphicsDesign, backendDevelopment } = data.sections.about_us.skills;

  return (
    <section className="about_section layout_padding-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-6">
            <div className="detail-box">
              <h2>{data.sections.about_us.title}</h2>
              <p>{data.sections.about_us.content}</p>

              {/* Skills Section */}
              <div className="skills-section">
                <h4>Skills</h4>
                <h5>Web Development</h5>
                <ul>
                  {webDevelopment.map((skill, index) => (
                    <li key={index}>{skill}</li>
                  ))}
                </ul>

                <h5>Mobile Development</h5>
                <ul>
                  {mobileDevelopment.map((skill, index) => (
                    <li key={index}>{skill}</li>
                  ))}
                </ul>

                <h5>UI/UX Design</h5>
                <ul>
                  {uiuxDesign.map((skill, index) => (
                    <li key={index}>{skill}</li>
                  ))}
                </ul>
                <h5>Video Editing</h5>
                <ul>
                  {videoEditing.map((skill, index) => (
                    <li key={index}>{skill}</li>
                  ))}
                </ul>

                <h5>Graphics Design</h5>
                <ul>
                  {graphicsDesign.map((skill, index) => (
                    <li key={index}>{skill}</li>
                  ))}
                </ul>

                <h5>Backend Development</h5>
                <ul>
                  {backendDevelopment.map((skill, index) => (
                    <li key={index}>{skill}</li>
                  ))}
                </ul>
              </div>

              <Link href="/contact">
                Hire Me
              </Link>
            </div>
          </div>
          <div className="col-lg-7 col-md-6">
            <div className="img-box">
              <img src={aboutImage} alt="About" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;