import React from 'react'
import { FaGlobe } from "react-icons/fa";
import { FaMobileScreenButton, FaDrawPolygon } from "react-icons/fa6";

function FeatureSection() {
  return (
    
    <section className="feature_section mt-5">
    <div className="container">
      <div className="feature_container">
        <div className="box">
          <div className="img-box">
            <FaGlobe/>
          </div>
          <h5 className="name">
            Web
          </h5>
        </div>
        <div className="box active">
          <div className="img-box">
            <FaMobileScreenButton/>
          </div>
          <h5 className="name">
            Mobile
          </h5>
        </div>
        <div className="box">
          <div className="img-box">
            <FaDrawPolygon/>
          </div>
          <h5 className="name">
            Graphics
          </h5>
        </div>
      </div>
    </div>
  </section>
  )
}

export default FeatureSection