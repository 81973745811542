import React from 'react'

function Footer() {
  let d = new Date();
  let year = d.getFullYear();
  return (
    <>
    <footer className="footer_section">
      <div className="container">
        <p>
          &copy; 2016 - <span id="displayDateYear" className='mr-5'>{year}</span> 
          Powered by <a href="https://msplus.com.ng" target='_blank' rel='noopener noreferrer'> Skillzdboss</a>
        </p>
      </div>
    </footer>
      <script src="../assets/js/jquery-3.4.1.min.js"></script>
      <script src="../assets/js/bootstrap.js"></script>
      <script src="../assets/js/custom.js"></script>
    </>
  )
}

export default Footer