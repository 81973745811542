import React from 'react'
import Header from '../Parts/Header'
import Footer from '../Parts/Footer'
import Sidebar from '../Parts/Sidebar'
import Navbar from '../Parts/Navbar'

function Main({ children }) {
  return (
    <div>
      <Header/>
      <Sidebar/>
      <Navbar/>
        { children }
      <Footer/>
    </div>
  )
}

export default Main