import React from 'react'
import Main from '../Layouts/Main'
import HeroSection from '../Parts/Hero'
import FeatureSection from '../Parts/Feature'
import AboutSection from '../Parts/About'
import ProfessionalSection from '../Parts/Professional'
import ClientSection from '../Parts/Client'

function Home() {
  return (
    <Main>
      <HeroSection/>
      <FeatureSection/>
      <AboutSection/>
      <ProfessionalSection/>
      <ClientSection/>
    </Main>
  )
}

export default Home