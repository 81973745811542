import React from 'react'
import Main from '../Layouts/Main'
import ContactSection from '../Parts/Contact'

function Contact() {
  return (
    <Main>
      <ContactSection/>
    </Main>
  )
}

export default Contact