const data = {
    name: "Promise Peter Akpan",
    email: "skillzdboss@gmail.com",
    countryCode: "+234",
    phone: `+2348089462425`,
    sections: {
        welcome: {
            title: "IT/Media Expert",
            content: `
            Welcome to my portfolio page, 
            I am an IT/Media expert, 
            I am easy to work with and 
            I am also a go getter.`
        },
        about_us: {
            title: "Bio",
            content: `
            Hi, I'm Promise Peter Akpan, a passionate Web Developer, Mobile App Developer, and Graphics Designer with a deep interest in creating visually appealing and functional digital solutions. With a keen eye for design and a strong technical foundation, I specialize in delivering seamless user experiences across web and mobile platforms.`,
            skills: {
                webDevelopment: ["React", "Node.js", "PHP", "HTML5", "CSS3", "JS6", "Ajax", "Laravel", "Python", "Jquery"],
                mobileDevelopment: ["React Native", "Expo"],
                uiuxDesign: ["Figma", "Adobe XD"],
                videoEditing: ["Adobe After Effects", "Windershare Filmora", "Capcut"],
                graphicsDesign: ["Photoshop", "Canva", "CorelDraw"],
                backendDevelopment: ["MySQL", "MongoDB", "PHP OOP", "Postgress", "No-Sql", "Sqlite", "mskdb", "Nodejs MVC"]
            }
        },
        services: {
            title: "Our Services",
            categories: {
                WebDevelopment: [
                    "Creating modern, responsive websites tailored to your needs.",
                    "Building personal blogs or complex e-commerce sites."
                ],
                MobileDevelopment: [
                    "Developing cross-platform mobile apps for iOS and Android.",
                    "Ensuring seamless user experiences across devices."
                ],
                UIUXDesign: [
                    "Designing user interfaces and experiences that are both functional and visually appealing.",
                    "Providing wireframes and final designs with a user-centric approach."
                ],
                GraphicsDesign: [
                    "Crafting stunning visuals for your brand, video editing, including logos and banners.",
                    "Using tools like Adobe Photoshop, CorelDraw, and Canva to bring ideas to life."
                ],
                Consultation: [
                    "Offering expert advice and strategies to help you achieve your digital goals.",
                    "Providing guidance on projects or complete overhauls as needed."
                ]
            }
        },
        contact: {
            title: "Contact Me",
            address: "No. 5 king Jesus close, Golf state, Trans Amadi, Rivers State, Nigeria",
            phone: "+2348089462425",
            email: "skillzdboss@gmail.com",
            socialLinks: {
              facebook: "https://facebook.com/skillzdboss",
              twitter: "https://twitter.com/code_skillz",
              youtube: "https://youtube.com/@skillzdboss",
              instagram: "https://instagram.com/skillzdboss"
            }
          }
    }
}

export default data;