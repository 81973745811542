import React from 'react'
import manImage from "../assets/images/slider-img.png"
import data from '../data'

function HeroSection() {
  return (
    <div className="hero_area">
      <section className="slider_section ">
        <div className="container ">
          <div className="row">
            <div className="col-md-6 ">
              <div className="detail-box">
                <h1>{data.sections.welcome.title}</h1>
                <p>{data.sections.welcome.content}</p>
                <a href="">
                  Contact Me
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box">
                <img src={manImage} alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
  )
}

export default HeroSection