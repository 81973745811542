import React from 'react'
import Main from '../Layouts/Main'
import ProfessionalSection from '../Parts/Professional';

function Services() {
  
  return (
    <Main>
      <ProfessionalSection/>
    </Main>
  )
}

export default Services