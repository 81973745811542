import React from 'react';
import profImage from "../assets/images/professional-img.png";
import data from '../data';
import { Link } from 'react-router-dom';

function ProfessionalSection() {
  return (
    <section className="professional_section layout_padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="img-box">
              <img src={profImage} alt="" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="detail-box">
              <h2>{data.sections.services.title}</h2>
              
              {/* Iterate through services categories */}
              {Object.keys(data.sections.services.categories).map((categoryKey, index) => (
                <div key={index}>
                  <h4>{categoryKey.replace(/([A-Z])/g, ' $1').trim()}</h4> {/* Converts camelCase to readable text */}
                  <ul>
                    {data.sections.services.categories[categoryKey].map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </div>
              ))}

              <Link href="/contact">
                Hire Me
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProfessionalSection;