import React from 'react';
import { FaFacebook, FaTwitter, FaYoutube, FaInstagram, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import { IoIosMail } from "react-icons/io";
import data from '../data';

function ContactSection() {
  // Inline styles for the social icons container
  const socialIconsStyle = {
    display: 'flex',
    justifyContent: 'center', // Center icons horizontally
    gap: '20px', // Space between icons
    marginTop: '20px' // Space above icons
  };

  // Inline styles for the social icons
  const iconStyle = {
    color: '#ffffff', // White color for icons
    textDecoration: 'none', // Remove underline from links
    fontSize: '30px' // Adjust icon size
  };

  return (
    <section className="info_section">
      <div className="container">
        <h4>{data.sections.contact.title}</h4>
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="info_items">
              <div className="row">
                <div className="col-md-4">
                  <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(data.sections.contact.address)}`} target='_blank' rel='noopener noreferrer'>
                    <div className="item">
                      <div className="img-box">
                        <FaMapMarkerAlt size={30}/>
                      </div>
                      <p>{data.sections.contact.address}</p>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a href={`tel:${data.sections.contact.phone}`} target='_blank' rel='noopener noreferrer'>
                    <div className="item">
                      <div className="img-box">
                        <FaPhone size={30}/>
                      </div>
                      <p>{data.sections.contact.phone}</p>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a href={`mailto:${data.sections.contact.email}`} target='_blank' rel='noopener noreferrer'>
                    <div className="item">
                      <div className="img-box">
                        <IoIosMail size={30}/>
                      </div>
                      <p>{data.sections.contact.email}</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="social-box">
        <h4>Follow Me</h4>
        <div style={socialIconsStyle}>
          <a href={data.sections.contact.socialLinks.facebook} style={iconStyle} target='_blank' rel='noopener noreferrer'>
            <FaFacebook />
          </a>
          <a href={data.sections.contact.socialLinks.twitter} style={iconStyle} target='_blank' rel='noopener noreferrer'>
            <FaTwitter />
          </a>
          <a href={data.sections.contact.socialLinks.youtube} style={iconStyle} target='_blank' rel='noopener noreferrer'>
            <FaYoutube />
          </a>
          <a href={data.sections.contact.socialLinks.instagram} style={iconStyle} target='_blank' rel='noopener noreferrer'>
            <FaInstagram />
          </a>
        </div>
      </div>
    </section>
  );
}

export default ContactSection;