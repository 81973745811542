import React from 'react'
import Main from '../Layouts/Main'
import AboutSection from '../Parts/About'

function About() {
  return (
    <Main>
      <AboutSection/>
    </Main>
  )
}

export default About